import { URLS } from "../../../utils/urls";
import { Navigate } from "react-router-dom";
import { isChkReservationCookieData } from "../../../lib/Util";

function CookieCheck () {
    
    //http://localhost:3000/reservation/reservation-history?flag=external&customerno=12642
    // if (!isChkReservationCookieData()) {
    //     if (document.location.search.indexOf('flag=external') >= 0) {
    //         // 일단 접근가능
    //         return true;
    //     }
    //     alert('잘못된 접근입니다.\n로그인 해주세요!')
    //     return false
    // }
    return true
}


export const ProtectedRouteReservation = ({children}) => {
    const isLoggedIn = CookieCheck()
    if (!isLoggedIn) {
        return <Navigate to ={URLS.EMAIL_LOGIN} replace />
    }
    return children
}