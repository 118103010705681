import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Input from '../atoms/Input';
import RadioGroup from '../atoms/Radio/RadioGroup';
import Radio from '../atoms/Radio';
import Select2 from '../atoms/Select2';
import { handleTimeChange, hourList2, minuteList2, handleTimeChange2 } from '../../utils/numbers';
import { setEndTime } from '../../reducers/reservation';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';

const TimeInput2 = (
    {
        start = false,
        end = false,
        setError,
        register,
        setValue,
        getValues,
        startHourRegister,
        startMinuteRegister,
        addHourRegister,
        dayTimeRegister,
        TimeRegister,
        HoursRegister,
        dayTimeError,
        TimeError,
        HoursError,
        errorMessage
    }) => {


    const [selectedStartHour, setSelectedStartHour] = useState('00')
    const [selectedStartMinute, setSelectedStartMinute] = useState('00')
    const [selectedHour, setSelectedHour] = useState(1)
    const [hourList, setHourList] = useState([])
    const dispatch = useDispatch();
    const contractOneDayAvaliableHour = useSelector((state) => state.Reservation.contractOnedayHour);
    const reduxEndTime = useSelector((state) => state.Reservation.usageTime.endTime);

    useEffect(() => {
        //console.log('init')
    }, [])


    useEffect(() => {
        //console.log('init2')
        if (contractOneDayAvaliableHour !== undefined && contractOneDayAvaliableHour !== null) {
            let tmpArr = [];
            for (let i = contractOneDayAvaliableHour; i <= 24; i++) {
                tmpArr.push(i)
            }
            setHourList(tmpArr)
            setSelectedHour(contractOneDayAvaliableHour)
        }
    }, [contractOneDayAvaliableHour])


    // 종료시간 업데이트를 위한 useEffect
    useEffect(() => {
        //console.log('init3')
        let tmpStr1 = moment(`2023-09-11 ${selectedStartHour}:${selectedStartMinute}`)
        let tmpStr2 = moment(`2023-09-11 ${selectedStartHour}:${selectedStartMinute}`).add(selectedHour, 'hours')
        const isNextDay = tmpStr1.day() !== tmpStr2.day();

        dispatch(setEndTime(tmpStr2.format('HH:mm'), isNextDay))
        setValue('endTime', tmpStr2.format('HH:mm'))
    }, [selectedStartHour, selectedStartMinute, selectedHour])



    return (
        <>
            <div className={classNames('time-input')}>
                {start && (
                    <>
                        <Select2
                            value={getValues('startHour')}
                            classes={classNames('select-small')}
                            selectedOption={selectedStartHour}
                            options={hourList2}
                            onOptionChange={hour => {
                                setValue(startHourRegister, hour)
                                setSelectedStartHour(hour)
                            }}
                        />
                        <span>시</span>
                        <Select2
                            value={getValues('startMinute')}
                            classes={classNames('select-small')}
                            selectedOption={selectedStartMinute}
                            options={minuteList2}
                            onOptionChange={minute => {
                                setValue(startMinuteRegister, minute)
                                setSelectedStartMinute(minute)

                            }}
                        />
                        <span>분 부터</span>
                        {/* <Select2
                            classes={classNames('select-small')}
                            selectedOption={selectedHour}
                            options={hourList}
                            onOptionChange={hour => {
                                setValue(HoursRegister, hour)
                                setSelectedHour(hour)

                            }}
                        /> */}
                        <span>8 시간</span>
                    </>
                )}
                {end && (
                    <>
                        {reduxEndTime.nextDayFlag ? '익일' : ''}
                        <Input
                            classes={'input'}
                            style={{ width: '100px' }}
                            type={'text'}
                            {...register(TimeRegister)}
                            maxLength={5}
                            required
                            error={TimeError}
                            placeholder={'-- : --'}

                            onChange={(e) => {
                                handleTimeChange2(e, setValue, TimeRegister)
                            }}
                            disabled
                        />
                        <span>까지</span>
                    </>
                )}
            </div>
            {(dayTimeError || TimeError || HoursError) && (
                <span className={classNames('error')}>
                    {errorMessage}
                </span>
            )}
        </>
    )
}

export default TimeInput2