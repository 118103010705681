import axios from 'axios';
import { Token } from '../constantData';
import { EncryptAES, DecryptAES, getCookieInfo } from './Util';
import sha1 from 'sha1';
import qs from 'qs'
import { KAKAO_CLIENT_ID } from '../utils/constant';

const TIME_OUT = 30000;
const USER_API_URL = (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) ? 'https://apitest.client.mosiler.com' : process.env.REACT_APP_USER_API_URL
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL

const AccountApi = {};


AccountApi.KakaoOauth = async (responseCode) => {
    const form = qs.stringify({
        grant_type: 'authorization_code',
        client_id: KAKAO_CLIENT_ID,
        code: responseCode,
        redirect_uri: process.env.REACT_APP_KAKAO_RESPONSEURL
    })

    let urlData = `https://kauth.kakao.com/oauth/token`

    let result = await axios({
        url: urlData,
        method: 'POST',
        timeout: TIME_OUT,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        data: form
    })
    return result.data
}

AccountApi.KakaoLoginedInfo = async (accessToken) => {
    let urlData = `https://kapi.kakao.com/v2/user/me`

    let result = await axios({
        url: urlData,
        method: 'GET',
        timeout: TIME_OUT,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },

    })
    return result.data
}

AccountApi.KakaoAccountSelect = async (infoData) => {
    let infoData2 = encodeURIComponent(EncryptAES(infoData))
    let urlData = `${USER_API_URL}/v3/KakaoAccountSelect?info=${infoData2}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.ConvertAccountTypeToKakao = async (kakaoid, phonenumber) => {
    let infoData = encodeURIComponent(EncryptAES(kakaoid))
    let infoData2 = encodeURIComponent(EncryptAES(phonenumber))
    let urlData = `${USER_API_URL}/v3/ConvertAccountTypeToKakao?kakaoid=${infoData}&phonenumber=${infoData2}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.KakaoLogin = (infoData) => {
    let urlData = `${USER_API_URL}/v3/KakaoLogin?info=${infoData}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token
            }
        }).then(async res => {
            resolve({ ...res.data, infoData: decodeURIComponent(infoData) });
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.SetVerifySms = (phone) => {
    let urlData = `${USER_API_URL}/SetVerifySms?phone=${phone}`

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.requestLogin = (id, pw) => {
    let obj = {
        id: id,
        pw: sha1(pw)
    }

    let infoData = encodeURIComponent(EncryptAES(JSON.stringify(obj)))
    let urlData = `${USER_API_URL}/v3/EmailLogin?info=${infoData}`
    /*
        "rYBVBmNCfEVnXiJv NBlMMkWBugGlSrBBvgCebW2O vL2pHcIKbrMAyzA6EAX1af6ALgyCOpiP5MP7hNrfRnL0Tovro4NYMfgmby8p/FKZM="
        "rYBVBmNCfEVnXiJv+NBlMMkWBugGlSrBBvgCebW2O+vL2pHcIKbrMAyzA6EAX1af6ALgyCOpiP5MP7hNrfRnL0Tovro4NYMfgmby8p/FKZM="

        알림
    */

    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token
            }
        }).then(async res => {
            resolve({ ...res.data, infoData: decodeURIComponent(infoData) });
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.SetLogOut = (CustomerNo) => {
    const CookieData = getCookieInfo()

    let data = JSON.parse(DecryptAES(CookieData.lgi)).CustomerNo



    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetLogOut?CustomerNo=${data}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.SetLogOutDd = (CustomerNo) => {
    const CookieData = getCookieInfo()

    let data = JSON.parse(DecryptAES(CookieData.lgi_dd)).CustomerNo



    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetLogOut?CustomerNo=${data}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
                'Authorize': CookieData.li_02_dd,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.SetMmEstimateJoinMember = (obj) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${WEB_API_URL}/SetMmEstimateJoinMember`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: obj
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}



AccountApi.SetMemberInvite = (obj) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetMemberInvite`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
            },
            data: obj
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


AccountApi.SetMemberTemp = (obj) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/SetMemberTemp`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
            },
            data: obj
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.GetSearchMasterEmailId = (email) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/GetSearchMasterEmailId?info=${email}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
            },

        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 아이디 찾기
AccountApi.FindUserId = (phoneNo) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/FindUserId?phoneNo=${phoneNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 비밀번호 찾기
AccountApi.FindPassword = (userId, phoneNo) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/FindPassword?userId=${userId}&phoneNo=${phoneNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Token': Token,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 파트너 자동화 페이지

// Jasmin 전용 로그인
AccountApi.JasminLogin = () => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${USER_API_URL}/v3/JasminLogin`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': 'A',
                'DeviceId': 'JASMIN',
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
    
}



/*


// 아이디 찾기
AccountApi.FindUserId = (phoneNo, ostype='', deviceid='') => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/FindUserId?phoneNo=${phoneNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}

// 비밀번호 찾기
AccountApi.FindPassword = (userId, phoneNo, ostype='', deviceid='') => {
    return new Promise((resolve, reject)=>{
        axios({
            url : `/FindPassword?userId=${userId}&phoneNo=${phoneNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type':'application/json',
                'OsType': ostype,
                'DeviceId': deviceid,
                'Token': Token
            }
        }).then(res => {
            resolve(res.data);
        }).catch( (err) => {
            return reject(err);
        });
    });
}



// kakaoLoginAuth : kakao 인증 실시
AccountApi.kakaoLoginAuth = () => {
    return new Promise ((resolve, reject) => {
        window.Kakao.Auth.login({
            
            success: function (authObj) {
                // alert(JSON.stringify(authObj))
                window.Kakao.API.request({
                    url: '/v2/user/me',
                    success: function (response) {
                        // alert(response);
                        resolve(response);
                        // if (response.kakao_account.email === undefined) {
                        //     alert('이메일 주소와 비밀번호를 입력하시고 로그인해주세요.')
                        // } else {

                        //     //alert('로그인 시도중...')
                        // }

                    },
                    fail: function (error) {
                        return reject(error);
                    }
                });

            },
            fail: function (err) {
                return reject(err);
            },
        })
    })
}


// AccountApi.kakaoLoginAuth2 = () => {
//     let Kakao = window.Kakao;
//     return new Promise ((resolve, reject) => {
//         Kakao.Auth.authorize({
//             redirectUri: 'http://192.168.219.100:3000/oauth'
//         });
//     })
// }

// 회원 가입
AccountApi.JoinForm = (objData, ostype='', deviceid='') => {
    
    return new Promise((resolve, reject) => {
        axios({
            url: `/JoinForm`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Token': Token,
            },
            data: objData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 회원 가입
AccountApi.CheckEmail = (email, ostype='', deviceid='') => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/CheckEmail?userId=${email}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
            }
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 회원 정보 수정
AccountApi.UpdateCustomer = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/UpdateCustomer`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

// 회원가입시 쓰이는 임시 데이터 삭제
AccountApi.removeJoinLocalStorageData = () => {
    localStorage.removeItem('couponAddFlag');
    localStorage.removeItem('AccountType');
    localStorage.removeItem('authChkResult');
    localStorage.removeItem('couponCode');
    localStorage.removeItem('deviceId');
    localStorage.removeItem('emailaddress');
    localStorage.removeItem('c_password');
    localStorage.removeItem('osType');
    localStorage.removeItem('name');
    localStorage.removeItem('c_emailid');
    localStorage.removeItem('phoneNum');
    localStorage.removeItem('check_status');
    localStorage.removeItem('memberjoin_parentcode');
    
}

// 회원정보가 업데이트 되었을때는 Login을 재시도 하고 성공하면 유저 정보를 취득한다.
// 유저 취득정보가 인자가 된다.
// 그 정보를 store에 저장시키고, cookie에도 저장한다.
AccountApi.updateMyInfo = async (loginResult, hash, cookies) =>{
    // redux store에 저장
    await store.dispatch(global_data_async(loginResult.Item));

    // 데이터를 쿠키에 저장
    CookieApi.SettingCookie(cookies, {
        UserId : loginResult.Item.UserId,
        AccountType : loginResult.Item.AccountType,
        hash : hash,
        LoginEncrypt : loginResult.Item.LoginEncrypt,
        OsType : loginResult.Item.OsType,
        DeviceId : loginResult.Item.DeviceId
    }, Number(cookies.get('auto_login_chk')))
}
// updateMyInfo 동작에서 LocalStorage 저장 처리를 추가
// 이 함수 호출 조건은 LocalStorage에서 데이터를 정상적으로 받아왔을때이다.
AccountApi.updateMyInfoWithLocalStorageLoginCheck = async (loginResult, lsData, cookies) =>{
    // redux store에 저장
    await store.dispatch(global_data_async(loginResult.Item));

    // 데이터를 쿠키에 저장
    CookieApi.SettingCookie(cookies, {
        UserId          : lsData.email_id,
        AccountType     : lsData.account_type,
        hash            : lsData.hashcode,
        LoginEncrypt    : loginResult.Item.LoginEncrypt,
        OsType          : lsData.ostype,
        DeviceId        : lsData.deviceid
    }, Number(lsData.auto_login_chk))
        
    // localStorage에 갱신
    localStorage.setItem('mosiler_cookie',JSON.stringify({
        account_type        : lsData.account_type,
        deviceid            : lsData.deviceid,
        email_id            : lsData.email_id, 
        login_encrypt       : loginResult.Item.LoginEncrypt,
        ostype              : lsData.ostype,
        auto_login_chk      : lsData.auto_login_chk,
        hashcode            : lsData.hashcode,
    }))

}

// LoginCheck 화면에서 로그인에 성공했을때는 아래 함수를 호출한다.
// 다른 로그인처리 부분도 봐야겠지만... 앵간하면 이 함수가 많이 쓰였음.하네..
AccountApi.LoginSuccessUpdateLoginInfo = async (loginResult, cookies) =>{
    // redux store에 저장
    await store.dispatch(global_data_async(loginResult.Item));

    // 데이터를 쿠키에 저장
    CookieApi.SettingCookie(cookies, {
        UserId          : cookies.get('email_id'),
        AccountType     : cookies.get('account_type'),
        hash            : cookies.get('hashcode'),
        LoginEncrypt    : loginResult.Item.LoginEncrypt,
        OsType          : cookies.get('ostype'),
        DeviceId        : cookies.get('deviceid'),
    }, Number(cookies.get('auto_login_chk')))
        
    // localStorage에 갱신
    localStorage.setItem('mosiler_cookie',JSON.stringify({
        email_id            : cookies.get('email_id'),
        account_type        : cookies.get('account_type'),
        hashcode            : cookies.get('hashcode'),
        login_encrypt       : loginResult.Item.LoginEncrypt,
        ostype              : cookies.get('ostype'),
        deviceid            : cookies.get('deviceid'),
        auto_login_chk      : cookies.get('auto_login_chk'),
    }))
}



AccountApi.add_partn_pref = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/add_partn_pref`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.Get_partn_pref = (customerno, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/Get_partn_pref?customerno=${customerno}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.update_partn_pref = (formData, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/update_partn_pref`,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
            data: formData
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

AccountApi.setFcmToken = (CustomerNo, fcmtoken, ostype='', deviceid='', loginEncrypt) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `/v2/SetFcmToken?regId=${fcmtoken}&customerNo=${CustomerNo}`,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'Token': Token,
                'OsType' : ostype,
                'DeviceId' : deviceid,
                'Authorize' : loginEncrypt,
            },
        }).then(res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

*/
export default AccountApi;