import axios from 'axios';
import { Token } from '../constantData';
import moment from 'moment';

const TIME_OUT = 10000;
const USER_API_URL = (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) ? 'https://apitest.client.mosiler.com' : process.env.REACT_APP_USER_API_URL


const ReportApi = {};

const getCookieInfo = () => {
    const CookieData2 = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    return CookieData2
}

ReportApi.GetMmReportContractDateList = (CustomerNo) => {
    let urlData = `${USER_API_URL}/v3/GetMmReportContractDateList?CustomerNo=${CustomerNo}`
    const CookieData = getCookieInfo()
    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

ReportApi.GetMmAvaliablePercent = (CustomerNo) => {
    let urlData = `${USER_API_URL}/v3/GetMmAvaliablePercent?CustomerNo=${CustomerNo}`
    const CookieData = getCookieInfo()
    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


ReportApi.GetMmReportList = (CustomerNo, startDate, endDate) => {
    let urlData = `${USER_API_URL}/v3/GetMmReportList?CustomerNo=${CustomerNo}&startDate=${startDate}&endDate=${endDate}`
    const CookieData = getCookieInfo()
    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}


ReportApi.GetDriverDispatchReportList = (CustomerNo, startDate, endDate) => {
    let urlData = `${USER_API_URL}/v3/GetDriverDispatchReportList?CustomerNo=${CustomerNo}&startDate=${startDate}&endDate=${endDate}`
    const CookieData = getCookieInfo()
    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02_dd,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}




ReportApi.GetMmAvaliablePercent = (CustomerNo) => {
    let urlData = `${USER_API_URL}/v3/GetMmAvaliablePercent?CustomerNo=${CustomerNo}`
    const CookieData = getCookieInfo()


    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// startDateString : '2023-01-01'
ReportApi.GetContractDateToArr = (startDateString) => {
    const startDate = new Date(startDateString);
    const currentDate = new Date(); // 현재 날짜
    const result = [];

    while (startDate <= currentDate) {
        const startDt = new Date(startDate);
        const endDt = new Date(startDate);
        endDt.setMonth(endDt.getMonth() + 1);
        endDt.setDate(endDt.getDate() - 1);

        result.push({
            StartDate: formatDate(startDt),
            EndDate: formatDate(endDt)
        });

        startDate.setMonth(startDate.getMonth() + 1);
    }

    return result;
}


ReportApi.GetReportExcelDownload = async (CustomerNo, StartDate, EndDate) => {
    let urlData = `${USER_API_URL}/v3/GetReportExcelDownload?CustomerNo=${CustomerNo}&startDate=${StartDate}&endDate=${EndDate}`
    const CookieData = getCookieInfo()

    try {
        let result = await axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            },
            responseType: 'blob'
        })
        const url2 = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a');
        link.href = url2;
        link.setAttribute('download', `월간모시러Report_${moment().format('yyyyMMDDHHmmss')}.xlsx`)
        document.body.appendChild(link)
        link.click()
    }
    catch (e) {

    }

}


ReportApi.DrivingDetailJasmin = (DrivingNo, PassengerPhone) => {
    let urlData = `${USER_API_URL}/v3/DrivingDetailJasmin?DrivingNo=${DrivingNo}&PassengerPhone=${PassengerPhone}`
    const CookieData = getCookieInfo()
    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'GET',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                'Authorize': CookieData.li_02,
                'Token': Token
            }
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}

export default ReportApi;