import axios from 'axios';
import { Token } from '../constantData';
import moment from 'moment';

const TIME_OUT = 10000;
const USER_API_URL = (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) ? 'https://apitest.client.mosiler.com' : process.env.REACT_APP_USER_API_URL


const JasminApi = {};

JasminApi.JasminCheck = (custNo, voucherCd ) => {
    let json = {
        custNo,
        voucherCd
    }

    let urlData = `${USER_API_URL}/v3/JasminCheck`
    // const CookieData = getCookieInfo()
    return new Promise((resolve, reject) => {
        axios({
            url: urlData,
            method: 'POST',
            timeout: TIME_OUT,
            headers: {
                'Content-Type': 'application/json',
                'OsType': '',
                'DeviceId': '',
                // 'Authorize': CookieData.li_02,
                'Token': Token
            },
            data:json
        }).then(async res => {
            resolve(res.data);
        }).catch((err) => {
            return reject(err);
        });
    });
}
/*const { Os } = useDetectBrowser()
    const onDownloadButton = () => {
        
        if (Os === 'android') {
            window.open('https://play.google.com/store/apps/details?id=com.mosiler.client')
        }
        else if (Os === 'ios') {
            window.open('https://apps.apple.com/kr/app/%EB%AA%A8%EC%8B%9C%EB%9F%AC-%EC%9A%B4%EC%A0%84-%EB%8F%99%ED%96%89-%EC%84%9C%EB%B9%84%EC%8A%A4/id1366011185')
        }
        
        */


JasminApi.JasminCloseProcess = (os) => {
    var eHyundaiApp = {};

    if (/Android/i.test(navigator.userAgent)) {
        eHyundaiApp.callApp = function (data) {
            var tmpData = JSON.stringify(data);
            HD_AOS.callApp(tmpData);
        };
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        eHyundaiApp.callApp = function (data) {
            webkit.messageHandlers.callApp.postMessage(data);
        };
    } else {
        eHyundaiApp.callApp = function() {
            window.close()
        }
    }

    var sendData = {};
    sendData = {
        'CMD': 'CLOSE'
        , 'PARAM': ''
        , 'CALLBACK': ''
    };

    eHyundaiApp.callApp(sendData);
}


export default JasminApi;